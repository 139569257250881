/* eslint-disable eqeqeq */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import Cookies from "./Cookies";
import Meta from "./Meta";

const Layout = ({ children }) => {
  const [showCookies, setShowCookies] = useState(false);

  const onAcceptCookies = (useCookies) => {
    if (typeof window === "undefined") return;
    localStorage.setItem("useCookies", useCookies);
    if (useCookies) {
      setShowCookies(false);
    } else {
      setShowCookies(true);
    }
  };

  useEffect(() => {
    if (typeof window === "undefined") return;
    const useCookies = localStorage.getItem("useCookies");
    if (useCookies) {
      setShowCookies(false);
    } else {
      setShowCookies(true);
    }
    if (useCookies == "reject") {
      console.log("LL Cookies Rejected");
      document.cookie = "ll-accepted-cookies=false";
      localStorage.setItem("ll-accepted-cookies", false);
      return;
    }
    if (useCookies == "accept") {
      console.log("LL Cookies Accepted");
      document.cookie = "ll-accepted-cookies=true";
      localStorage.setItem("ll-accepted-cookies", true);
      return;
    }
  }, [showCookies]);

  return (
    <div className="main">
      <Meta />
      {children}
      <Cookies show={showCookies} onAcceptCookies={onAcceptCookies} />
    </div>
  );
};

export default Layout;
