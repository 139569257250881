import "../styles/globals.css";
import "../styles/fonts/stylesheet.css";
import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";


import Layout from "../components/Layout";

const defaultVariants = {
  out: {
    opacity: 0,
    left: "100%",
    transition: {
      duration: 1,
      delay: 0.25,
    },
  },
  in: {
    opacity: 1,
    left: 0,
    transition: {
      duration: 1,
    },
  },
};

const onExitCompleteHandler = () => {
  window.scrollTo(0, 0);
};

function MyApp({ Component, pageProps }) {
  const { asPath } = useRouter();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const layoutContainer = document.querySelector(".layout-container");
      layoutContainer.classList.remove("first-load");
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
    <div className="layout-container first-load">
      <AnimatePresence mode="wait" onExitComplete={onExitCompleteHandler}>
        <motion.div
          key={asPath}
          variants={defaultVariants}
          animate="in"
          initial="out"
          exit="out"
        >
                <Layout>
                  <Component {...pageProps} />
                </Layout>
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
}

export default MyApp;
