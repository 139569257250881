import { CookiesStyles } from "./CookiesStyles";

const Cookies = ({ show, onAcceptCookies }) => {
  if (!show) return "";
  return (
    <CookiesStyles>
      <div className="cookies-container">
        <div className="description">We are using cookies to give you the best experience on our website.</div>
        <div className="buttons">
          <div className="reject" onClick={() => onAcceptCookies("reject")}>
            REJECT ALL
          </div>
          <div className="accept" onClick={() => onAcceptCookies("accept")}>
            ACCEPT ALL
          </div>
        </div>
      </div>
    </CookiesStyles>
  );
};

export default Cookies;
