import styled from "styled-components";

export const CookiesStyles = styled.div`
  position: fixed;
  bottom: 22px;
  right: 22px;
  width: 362px;
  height: auto;
  z-index: 99;
  background: #fff;
  padding: 30px;

  @media only screen and (max-width: 767px) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
    padding: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .buttons {
    display: flex;
    margin-top: 26px;

    > div {
      font-family: "Moderat";
      background: #1f00b7;
      color: #fff;
      width: 160px;
      height: 48px;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: center;
      padding: 14px 0;
      border: 1.6px solid #1f00b7;
      outline: none !important;
      transition: 0.5s ease all;
      cursor: pointer;

      &:hover {
        font-weight: 500;
        background: transparent;
        color: #1f00b7;
      }

      &.reject {
        margin-right: 14px;
        background: #fff;
        color: #1f00b7;
      }
    }
  }
`;
